body {
  margin: 0;
}

#root {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.Mui-disabled {
  cursor: not-allowed;
}